import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import dayjs from "dayjs";
import { Subscription } from "rxjs";
import { ChartDataGroup } from "../../charts/models/chart-data-group.model";
import { ChartData } from "../../charts/models/chart-data.model";
import { LineChartSettings } from "../../charts/models/chart-settings.model";
import {
  BrandCampaignInsight,
  BrandCampaignInsightChart,
} from "../../models/brandCampaignInsight";
import { facebookAdsKPIChartDataSettings } from "./chart-settings/facebook-ads-kpi-chart-data-settings";
import {
  FacebookPostsPerDayChartFilters,
  facebookPostsDailyMetricsChartDataSettings,
} from "./chart-settings/facebook-posts-daily-metrics-chart-data-settings";
import { googleAdsKPIChartDataSettings } from "./chart-settings/google-ads-kpi-chart-data-settings";
import { KPI_CHART_SETTINGS } from "./chart-settings/kpi-chart-settings";
import {
  InstagramPostsPerDayChartFilters,
  instagramPostsDailyMetricsChartDataSettings,
} from "./chart-settings/instagram-posts-daily-metrics-chart-data-settings";
import {
  LinkedInPostsPerDayChartFilters,
  linkedInPostsDailyMetricsChartDataSettings,
} from "./chart-settings/linkedin-posts-daily-metrics-chart-data-settings";
import { pointOfSaleChartSettings } from "./chart-settings/point-of-sale-chart-settings";
import { posOrdersPerDayChartDataSettings } from "./chart-settings/pos-orders-per-day-chart-data-settings";
import { AdsKPIChartFilters } from "./chart-settings/types";
import { DataSettings } from "../../charts/models/data-settings.model";
import { DecimalPipe, NgClass, NgIf, UpperCasePipe } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ChartComponent } from "../chart/chart.component";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-campaign-dashboard-daily-charts",
  templateUrl: "./campaign-dashboard-daily-charts.component.html",
  styleUrl: "./campaign-dashboard-daily-charts.component.scss",
  standalone: true,
  imports: [
    ChartComponent,
    DecimalPipe,
    NgClass,
    NgIf,
    TranslateModule,
    UpperCasePipe,
  ],
})
export class CampaignDashboardDailyChartsComponent
  implements OnDestroy, OnInit
{
  private currentInsights!: BrandCampaignInsight;
  private currentInsightCharts!: BrandCampaignInsightChart;

  @Input({ required: true }) public set insightCharts(
    insightCharts: BrandCampaignInsightChart,
  ) {
    this.currentInsightCharts = insightCharts;
  }

  public get insightCharts() {
    return this.currentInsightCharts;
  }

  @Input({ required: true }) public set insights(
    insights: BrandCampaignInsight,
  ) {
    this.currentInsights = insights;
    this.showGoogleAdsCharts =
      insights.googleAdsStats &&
      insights.promoAdsEnabled &&
      insights.useGoogleAds;

    this.showFBAdsCharts =
      insights.facebookAdsStats &&
      insights.promoAdsEnabled &&
      insights.useFacebookAds;

    this.showLinkedInCharts =
      insights.linkedinPostsStats && insights.useLinkedin;

    this.loadCharts();
  }

  public get insights() {
    return this.currentInsights;
  }

  protected showGoogleAdsCharts = false;
  protected showFBAdsCharts = false;
  protected showLinkedInCharts = false;

  protected googleAdsKPIChartSettings?: LineChartSettings;
  protected facebookAdsKPIChartSettings?: LineChartSettings;
  protected facebookPostsPerDayChartSettings?: LineChartSettings;
  protected instagramPostsPerDayChartSettings?: LineChartSettings;
  protected linkedInPostsPerDayChartSettings?: LineChartSettings;
  protected posOrdersPerDayChartSettings?: LineChartSettings;

  protected readonly facebookPostsPerDayChartFilters: FacebookPostsPerDayChartFilters =
    {
      impressions: true,
      comments: true,
      clicks: true,
      shares: true,
      likes: true,
    };

  protected readonly facebookAdsKPIChartFilters: AdsKPIChartFilters = {
    impressions: true,
    engagements: true,
    clicks: true,
    views: true,
  };

  protected readonly instagramPostsPerDayChartFilters: InstagramPostsPerDayChartFilters =
    {
      engagement: true,
      impressions: true,
      reach: true,
      videoViews: true,
    };

  protected readonly linkedInPostsPerDayChartFilters: LinkedInPostsPerDayChartFilters =
    {
      impressions: true,
      comments: true,
      clicks: true,
      shares: true,
      likes: true,
    };

  protected readonly googleAdsKPIChartFilters: AdsKPIChartFilters = {
    impressions: true,
    engagements: true,
    clicks: true,
    views: true,
  };

  private subs = new Subscription();
  private chartLoaded = false;

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public ngOnInit(): void {
    this.showGoogleAdsCharts =
      this.insights.googleAdsStats &&
      this.insights.promoAdsEnabled &&
      this.insights.useGoogleAds;

    this.showFBAdsCharts =
      this.insights.facebookAdsStats &&
      this.insights.promoAdsEnabled &&
      this.insights.useFacebookAds;

    this.showLinkedInCharts =
      this.insights.linkedinPostsStats && this.insights.useLinkedin;

    if (!this.chartLoaded) {
      this.loadCharts();
    }
  }

  private createLineChartSettings<
    Value extends { [K in keyof Value]: number },
    Filters extends { [K in keyof Filters]: boolean },
  >(
    groups: DataSettings<keyof Value, keyof Filters>[],
    data: Value[],
    filters: Filters,
  ): LineChartSettings {
    return new LineChartSettings(
      KPI_CHART_SETTINGS,
      groups.map(
        (config) =>
          new ChartDataGroup(
            config.name,
            config.color,
            Object.entries(data).map(
              ([date, item]) =>
                new ChartData(
                  dayjs(date).format("MMM D"),
                  item[config.valueKey],
                ),
            ),
            filters[config.filterKey],
          ),
      ),
    );
  }

  protected loadFacebookPostsPerDayChart(): void {
    this.facebookPostsPerDayChartSettings = this.createLineChartSettings(
      facebookPostsDailyMetricsChartDataSettings,
      this.insightCharts.facebookPostsDailyMetricsChart,
      this.facebookPostsPerDayChartFilters,
    );
  }

  protected loadInstagramPostsPerDayChart(): void {
    this.instagramPostsPerDayChartSettings = this.createLineChartSettings(
      instagramPostsDailyMetricsChartDataSettings,
      this.insightCharts.instagramPostsDailyMetricsChart,
      this.instagramPostsPerDayChartFilters,
    );
  }

  protected loadGoogleAdsKPIChart(): void {
    this.googleAdsKPIChartSettings = this.createLineChartSettings(
      googleAdsKPIChartDataSettings,
      this.insightCharts.googleAdsDailyMetricsChart,
      this.googleAdsKPIChartFilters,
    );
  }

  protected loadFacebookAdsKPIChart(): void {
    this.facebookAdsKPIChartSettings = this.createLineChartSettings(
      facebookAdsKPIChartDataSettings,
      this.insightCharts.facebookAdsDailyMetricsChart,
      this.facebookAdsKPIChartFilters,
    );
  }

  private loadPosOrdersPerDayChart(): void {
    this.posOrdersPerDayChartSettings = new LineChartSettings(
      pointOfSaleChartSettings,
      posOrdersPerDayChartDataSettings.map(
        (config) =>
          new ChartDataGroup(
            config.name,
            config.color,
            Object.entries(this.insightCharts.posOrdersPerDayChart).map(
              ([date, item]) =>
                new ChartData(dayjs(date).format("MMM D"), item),
            ),
          ),
      ),
    );
  }

  protected loadLinkedInPostsPerDayChart(): void {
    this.linkedInPostsPerDayChartSettings = this.createLineChartSettings(
      linkedInPostsDailyMetricsChartDataSettings,
      this.insightCharts.linkedinPostsDailyMetricsChart,
      this.linkedInPostsPerDayChartFilters,
    );
  }

  private loadCharts(): void {
    this.chartLoaded = true;

    if (this.showGoogleAdsCharts) {
      this.loadGoogleAdsKPIChart();
    }

    if (this.showFBAdsCharts) {
      this.loadFacebookAdsKPIChart();
    }

    if (this.insights.facebookPostsStats) {
      this.loadFacebookPostsPerDayChart();
      this.loadInstagramPostsPerDayChart();
      this.loadLinkedInPostsPerDayChart();
    }

    if (this.insights.includePos) {
      this.loadPosOrdersPerDayChart();
    }
  }
}
