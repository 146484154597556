import { KeysByType } from "../../../../../harmony/core";
import { DataSettings } from "../../../charts/models/data-settings.model";
import { BrandCampaignInsight } from "../../../models/brandCampaignInsight";

export const campaignBudgetDoughnutChartDataSettings: DataSettings<
  KeysByType<BrandCampaignInsight, number>
>[] = [
  new DataSettings(
    "campaignPendingBudget",
    "campaignPendingBudget",
    "brand.campaign.dashboard.remaining",
    "#4a90e2",
  ),
  new DataSettings(
    "campaignSpentBudget",
    "campaignSpentBudget",
    "partner.campaign.header.spent",
    "#f28a3d",
  ),
];
