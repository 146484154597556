<div class="brand-dashboard-stats">
  <section>
    <section class="campaign-information">
      <h3>{{ "brand.campaign.dashboard.campaignInfo" | translate }}</h3>
      <div class="statistics card">
        <div
          class="statistic-param"
          *ngIf="
            insights.campaignType === CampaignType.Contest ||
            insights.campaignType === CampaignType.ProductTester
          "
        >
          <p class="amount">
            {{ insights.totalParticipants | number: "1.0-2" }}
          </p>
          <p class="type">
            {{ "brand.campaign.dashboard.participants" | translate }}
          </p>
          <p class="time">{{ "brand.campaign.dashboard.total" | translate }}</p>
        </div>
        <div class="statistic-param">
          <p class="amount">
            {{ insights.totalEngagedPartners | number: "1.0-2" }}
          </p>
          <p class="type">
            {{ "brand.campaign.dashboard.activePartners" | translate }}
          </p>
          <p class="time">{{ "brand.campaign.dashboard.total" | translate }}</p>
        </div>
        <div class="statistic-param">
          <p class="amount">
            {{ insights.campaignDuration | number: "1.0-2" }}
          </p>
          <p class="type">
            {{ "brand.campaign.dashboard.duration" | translate }}
          </p>
          <p class="time">
            {{ "brand.campaign.dashboard.durationInDays" | translate }}
          </p>
        </div>
        <div
          *ngIf="insights.promoAdsEnabled && insights.useFacebookAds"
          class="statistic-param"
        >
          <p class="amount">
            {{ insights.totalFacebookAds | number: "1.0-2" }}
          </p>
          <p class="type">
            {{ "brand.campaign.dashboard.facebookAds" | translate }}
          </p>
          <p class="time">{{ "brand.campaign.dashboard.total" | translate }}</p>
        </div>
        <div
          *ngIf="insights.promoAdsEnabled && insights.useGoogleAds"
          class="statistic-param"
        >
          <p class="amount">{{ insights.totalGoogleAds | number: "1.0-2" }}</p>
          <p class="type">
            {{ "brand.campaign.dashboard.googleAds" | translate }}
          </p>
          <p class="time">{{ "brand.campaign.dashboard.total" | translate }}</p>
        </div>
        <div class="statistic-param">
          <p class="amount">
            {{ insights.totalLinkClicks | number: "1.0-2" }}
          </p>
          <p class="type">
            {{ "brand.campaign.dashboard.linkClicks" | translate }}
          </p>
          <p class="time">{{ "brand.campaign.dashboard.total" | translate }}</p>
        </div>
      </div>
    </section>

    <section class="promo-ads">
      <h3>{{ "brand.campaign.dashboard.promoAds" | translate }}</h3>
      <div class="double-card">
        <div class="card left">
          <div class="left-content">
            <h4 class="campaign-budget">
              {{ "brand.campaign.dashboard.campaignBudget" | translate }}
            </h4>
            <div class="big-chart-container">
              <app-chart [settings]="campaignBudgetChartSettings" />
            </div>
          </div>
          <div class="right-content">
            <div class="statistic-param statistic-param--campaign-budget">
              <p class="amount">
                {{ insights.currencySymbol
                }}{{ insights.campaignTotalBudget | number: "1.0-2" }}
              </p>
              <p class="type">
                {{ "brand.campaign.dashboard.campaignBudget" | translate }}
              </p>
            </div>
            <div class="legend">
              <div class="legend-line">
                <div class="color blue">&nbsp;</div>
                <p>
                  <span class="amount"
                    >{{ insights.currencySymbol
                    }}{{
                      insights.campaignPendingBudget | number: "1.0-2"
                    }}</span
                  >
                  {{ "brand.campaign.dashboard.remaining" | translate }}
                </p>
              </div>
              <div class="legend-line">
                <div class="color orange">&nbsp;</div>
                <p>
                  <span class="amount"
                    >{{ insights.currencySymbol
                    }}{{ insights.campaignSpentBudget | number: "1.0-2" }}</span
                  >
                  {{ "shared.spent" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card right">
          <div class="param-with-title">
            <h4>
              {{ "brand.campaign.dashboard.totalKPIForPromoAds" | translate }}
              <app-tooltip
                text="{{
                  'brand.campaign.dashboard.totalKPIForPromoAdsToolTip'
                    | translate
                }}"
              />
            </h4>
            <div class="statistic-param-wrap">
              <div class="statistic-param statistic-param--full-width">
                <p class="amount">
                  {{ insights.adsStats.impressions | number: "1.0-2" }}
                </p>
                <p class="type">
                  <mat-icon>visibility</mat-icon>
                  {{ "brand.campaign.dashboard.adImpressions" | translate }}
                </p>
              </div>
              <div class="statistic-param statistic-param--full-width">
                <p class="amount">
                  {{ insights.adsStats.reach | number: "1.0-2" }}
                </p>
                <p class="type">
                  <img src="/assets/images/icons/ic_comments.svg" />{{
                    "brand.campaign.dashboard.adReach" | translate
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="param-with-title">
            <h4>
              {{ "brand.campaign.dashboard.promoAdsCostTitle" | translate }}
              <app-tooltip
                text="{{
                  'brand.campaign.dashboard.promoAdsCostToolTip' | translate
                }}"
              />
            </h4>
            <div class="statistic-param-wrap">
              <div class="statistic-param statistic-param--full-width">
                <p class="amount">
                  {{ insights.currencySymbol }}{{ costPerImpression() }}
                </p>
                <p class="type">
                  {{ "brand.campaign.dashboard.cost1KImpression" | translate }}
                </p>
              </div>
              <div class="statistic-param statistic-param--full-width">
                <p class="amount">
                  {{ insights.totalLinkClicks | number: "1.0-2" }}
                </p>
                <p class="type">
                  {{ "brand.campaign.dashboard.adLinkClicks" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="param-with-title" *ngIf="insights.promoAdsEnabled">
            <h4>
              {{ "brand.campaign.dashboard.costPerAdType" | translate }}
              <app-tooltip
                text="{{
                  'brand.campaign.dashboard.costPerAdTypeToolTip' | translate
                }}"
              />
            </h4>
            <div class="statistic-param-wrap">
              <div
                *ngIf="insights.useFacebookAds"
                class="statistic-param statistic-param--full-width"
              >
                <p class="amount">
                  {{ insights.currencySymbol
                  }}{{ insights.facebookAdsBudget | number: "1.0-2" }}
                </p>
                <p class="type">
                  {{ "brand.campaign.dashboard.facebookAds" | translate }}
                </p>
              </div>
              <div
                *ngIf="insights.useGoogleAds"
                class="statistic-param statistic-param--full-width"
              >
                <p class="amount">
                  {{ insights.currencySymbol
                  }}{{ insights.googleAdsBudget | number: "1.0-2" }}
                </p>
                <p class="type">
                  {{ "brand.campaign.dashboard.googleAds" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>

  <section class="total-posts">
    <div class="card">
      <app-doughnut-chart-with-legend
        name="brand.campaign.dashboard.totalPostsPieChart"
        [settings]="totalPostsPieChartSettings"
        [legendLines]="totalPostsLegends"
        totalText="brand.campaign.dashboard.totalPostsPieChart"
        [totalValue]="
          insights.totalPublishedFacebookAds +
          insights.totalPublishedGoogleAds +
          insights.totalPublishedFacebookPosts +
          insights.totalPublishedLinkedinPosts +
          insights.totalPublishedInstagramPosts
        "
      />

      <app-doughnut-chart-with-legend
        name="brand.campaign.dashboard.postsByType"
        [settings]="postsByTypePieChartSettings"
        [legendLines]="postsByTypeLegends"
        [totalValue]="
          insights.totalPosts.totalImages +
          insights.totalPosts.totalVideos +
          insights.totalPosts.totalCarousel +
          insights.totalPosts.totalGif
        "
        totalText="brand.campaign.dashboard.totalPostsByTypePieChart"
      />

      <app-doughnut-chart-with-legend
        name="brand.campaign.dashboard.engagementLevelsChartTitle"
        [settings]="engagementPieChartSettings"
        [legendLines]="engagementLegends"
        [totalValue]="insights.totalEngagedPartners"
        totalText="brand.campaign.dashboard.totalEngaged"
      />

      <app-doughnut-chart-with-legend
        name="brand.campaign.dashboard.partnerStatusChartTitle"
        [settings]="partnerStatusPieChartSettings"
        [legendLines]="partnerStatusLegends"
        [totalValue]="insights.totalPartners"
        totalText="brand.campaign.dashboard.totalPartners"
      />
    </div>
  </section>
</div>
