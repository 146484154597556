import { DataSettings } from "../../../charts/models/data-settings.model";
import { LinkedInPostStats } from "../../../models/brandCampaignInsight";

export interface LinkedInPostsPerDayChartFilters {
  impressions: boolean;
  comments: boolean;
  clicks: boolean;
  shares: boolean;
  likes: boolean;
}

export const linkedInPostsDailyMetricsChartDataSettings: DataSettings<
  keyof LinkedInPostStats,
  keyof LinkedInPostsPerDayChartFilters
>[] = [
  new DataSettings("impressions", "impressions", "shared.views", "#4a90e2"),
  new DataSettings(
    "likes",
    "likes",
    "partner.dashboard.KPIChart.likes",
    "#ca93f4",
  ),
  new DataSettings("comments", "comments", "shared.comments", "#d0021b"),
  new DataSettings("shares", "shares", "shared.shares", "#f28a3d"),
  new DataSettings(
    "clicks",
    "clicks",
    "partner.dashboard.KPIChart.clicks",
    "#1dbf77",
  ),
];
