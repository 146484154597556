import { DataSettings } from "../../../charts/models/data-settings.model";
import { BrandCampaignInsight } from "../../../models/brandCampaignInsight";

export const postsByTypeDoughnutChartDataSettings: DataSettings<
  keyof BrandCampaignInsight["totalPosts"]
>[] = [
  new DataSettings("totalImages", "totalImages", "shared.image", "#f44336"),
  new DataSettings("totalVideos", "totalVideos", "shared.video", "#cec22b"),
  new DataSettings(
    "totalCarousel",
    "totalCarousel",
    "brand.campaign.content.addContent.carousel",
    "#4a90e2",
  ),
  new DataSettings("totalGif", "totalGif", "shared.gif", "#f28a3d"),
];
