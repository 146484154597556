import { formatNumber } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import type { ChartConfiguration } from "chart.js";

export const doughnutWithTextChartSettings = (
  translateService: TranslateService,
): ChartConfiguration<"doughnut"> => ({
  data: {
    datasets: [],
  },
  type: "doughnut",
  options: {
    cutout: 78,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  },
  plugins: [
    {
      id: "stackedText",
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      afterDatasetDraw: function (chart, _args, _options) {
        const {
          ctx,
          data,
          chartArea: { top, width, height },
        } = chart;

        const fontSize = 28;

        ctx.save();
        ctx.font = `bolder ${fontSize}px Open Sans`;
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.fillText(
          `€${formatNumber(
            Number(data.datasets[0].data[0]),
            "en-US",
            "1.0-2",
          )}`,
          width / 2,
          height / 2 + top,
        );
        ctx.restore();

        ctx.font = `13px Open Sans`;
        ctx.fillStyle = "#9a9a9a";
        ctx.textAlign = "center";

        ctx.fillText(
          translateService.instant("brand.campaign.dashboard.remaining"),
          width / 2,
          height / 2 + top + 20,
        );
        ctx.restore();
      },
    },
  ],
});
