import { inject, Injectable } from "@angular/core";
import { Logger } from "../../../harmony/core";
import { AuthenticationService } from "./api/authentication.service";
import { createDeferred, Deferred } from "./deferred";
import { environment } from "../../../environments/environment";

export type Flag = "new-prospect-partners";
export type Flags = Record<Flag, boolean>;

@Injectable()
export class FeatureFlagsService {
  private remoteFlags: Deferred<Flags> = createDeferred();
  private readonly logger = inject(Logger).withTag("FeatureFlagsService");

  constructor(private readonly authService: AuthenticationService) {}

  public getFlags(): Promise<Flags> {
    return this.remoteFlags.promise;
  }

  public async isEnabled(flag: Flag): Promise<boolean> {
    const flags = await this.remoteFlags.promise;
    return flags[flag] ?? false;
  }

  public async load(): Promise<void> {
    if (this.remoteFlags.isFulfilled) {
      this.remoteFlags = createDeferred();
    }

    // TODO: The following section should come from the API
    this.remoteFlags.resolve({
      "new-prospect-partners": this.isProspectPartnersEnabled(),
    });
    // TODO: end of section.

    // Log current flags
    const flags = Object.entries(await this.getFlags());

    flags.forEach(([flag, state]) =>
      this.logger.info(`${flag} = ${state ? "true" : "false"}`),
    );
  }

  public isProspectPartnersEnabled(): boolean {
    return (
      environment.features.prospectPartners && !this.authService.isWhiteLabel()
    );
  }
}
