import { DataSettings } from "../../../charts/models/data-settings.model";
import { InstagramPostStats } from "../../../models/brandCampaignInsight";

export interface InstagramPostsPerDayChartFilters {
  engagement: boolean;
  impressions: boolean;
  reach: boolean;
  videoViews: boolean;
}

export const instagramPostsDailyMetricsChartDataSettings: DataSettings<
  keyof InstagramPostStats,
  keyof InstagramPostsPerDayChartFilters
>[] = [
  new DataSettings(
    "instagramImpressions",
    "impressions",
    "partner.dashboard.KPIChart.impressions",
    "#4a90e2",
  ),
  new DataSettings(
    "instagramEngagement",
    "engagement",
    "shared.engagements",
    "#d0021b",
  ),
  new DataSettings(
    "instagramReach",
    "reach",
    "partner.dashboard.KPIChart.reach",
    "#1dbf77",
  ),
  new DataSettings(
    "instagramVideoViews",
    "videoViews",
    "shared.videoViews",
    "#ca93f4",
  ),
];
