import { KeysByType } from "../../../../../harmony/core";
import { DataSettings } from "../../../charts/models/data-settings.model";
import { BrandCampaignInsight } from "../../../models/brandCampaignInsight";

export const totalPostsDoughnutChartDataSettings: DataSettings<
  KeysByType<BrandCampaignInsight, number>
>[] = [
  new DataSettings(
    "totalPublishedFacebookPosts",
    "totalPublishedFacebookPosts",
    "brand.campaign.dashboard.facebookPosts",
    "#4a90e2",
  ),
  new DataSettings(
    "totalPublishedInstagramPosts",
    "totalPublishedInstagramPosts",
    "brand.campaign.dashboard.instagramPosts",
    "#d65db1",
  ),
  new DataSettings(
    "totalPublishedFacebookAds",
    "totalPublishedFacebookAds",
    "brand.campaign.summary.facebookPromoAds",
    "#f28a3d",
  ),
  new DataSettings(
    "totalPublishedGoogleAds",
    "totalPublishedGoogleAds",
    "brand.campaign.promoAds.googleAds",
    "#1dbf77",
  ),
  new DataSettings(
    "totalPublishedLinkedinPosts",
    "totalPublishedLinkedinPosts",
    "brand.campaign.promoAds.linkedinPosts",
    "#3DD1F2",
  ),
];
