<div class="brand-dashboard-charts-wrapper">
  <!-- GOOGLE ADS CHART THIS WILL BE USED WHE WE HAVE DAILY KPIS -->
  <ng-container *ngIf="showGoogleAdsCharts">
    <section class="google-ads-per-day">
      <div class="card">
        <div class="header">
          <h4>
            {{
              "brand.campaign.dashboard.googleKPICharTitle"
                | translate
                | uppercase
            }}
          </h4>
          <div class="filters" *ngIf="googleAdsKPIChartFilters">
            <button
              class="filter"
              (click)="
                googleAdsKPIChartFilters.impressions =
                  !googleAdsKPIChartFilters.impressions;
                loadGoogleAdsKPIChart()
              "
              [ngClass]="{
                'filter-active': googleAdsKPIChartFilters.impressions,
                'filter-inactive': !googleAdsKPIChartFilters.impressions
              }"
            >
              <div class="bullet impressions"></div>
              {{ "shared.impressions" | translate }}
            </button>
            <button
              class="filter"
              (click)="
                googleAdsKPIChartFilters.views =
                  !googleAdsKPIChartFilters.views;
                loadGoogleAdsKPIChart()
              "
              [ngClass]="{
                'filter-active': googleAdsKPIChartFilters.views,
                'filter-inactive': !googleAdsKPIChartFilters.views
              }"
            >
              <div class="bullet views"></div>
              {{ "shared.reach" | translate }}
            </button>
            <button
              class="filter"
              (click)="
                googleAdsKPIChartFilters.clicks =
                  !googleAdsKPIChartFilters.clicks;
                loadGoogleAdsKPIChart()
              "
              [ngClass]="{
                'filter-active': googleAdsKPIChartFilters.clicks,
                'filter-inactive': !googleAdsKPIChartFilters.clicks
              }"
            >
              <div class="bullet clicks"></div>
              {{ "shared.clicks" | translate }}
            </button>
          </div>
        </div>
        <div class="line-chart">
          <app-chart [settings]="googleAdsKPIChartSettings"></app-chart>
        </div>
        <div class="param-with-title">
          <div
            class="statistic-param"
            *ngIf="googleAdsKPIChartFilters.impressions"
          >
            <div class="color-bar impressions">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.impressions" | translate }}</p>
              <p class="amount">
                {{ insights.googleAdsStats.impressions | number: "1.0-2" }}
              </p>
            </div>
          </div>
          <div class="statistic-param" *ngIf="googleAdsKPIChartFilters.views">
            <div class="color-bar views">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.reach" | translate }}</p>
              <p class="amount">
                {{ insights.googleAdsStats.views | number: "1.0-2" }}
              </p>
            </div>
          </div>
          <div class="statistic-param" *ngIf="googleAdsKPIChartFilters.clicks">
            <div class="color-bar link-clicks">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.clicks" | translate }}</p>
              <p class="amount">
                {{ insights.googleAdsStats.linkClicks | number: "1.0-2" }}
              </p>
            </div>
          </div>
          <div class="statistic-param statistic-param--daily-chart-money">
            <p class="amount">
              {{ insights.currencySymbol
              }}{{ insights.googleAdsBudget | number: "1.0-2" }}
            </p>
            <p class="type">
              {{ "brand.campaign.dashboard.totalBudget" | translate }}
            </p>
          </div>
          <div class="statistic-param statistic-param--daily-chart-money">
            <p class="amount">
              {{ insights.currencySymbol
              }}{{ insights.googleAdsStats.spend | number: "1.0-2" }}
            </p>
            <p class="type">
              {{ "brand.campaign.dashboard.budgetSpent" | translate }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
  <!-- FACEBOOK ADS CHART -->
  <ng-container *ngIf="showFBAdsCharts">
    <section class="facebook-ads-per-day">
      <div class="card">
        <div class="header">
          <h4 *ngIf="!insights.publisherPlatformInstagram">
            {{
              "brand.campaign.dashboard.facebookKPICharTitle"
                | translate
                | uppercase
            }}
          </h4>
          <h4 *ngIf="insights.publisherPlatformInstagram">
            {{
              "brand.campaign.dashboard.facebookInstagramKPICharTitle"
                | translate
                | uppercase
            }}
          </h4>
          <div class="filters" *ngIf="facebookAdsKPIChartFilters">
            <button
              class="filter"
              (click)="
                facebookAdsKPIChartFilters.impressions =
                  !facebookAdsKPIChartFilters.impressions;
                loadFacebookAdsKPIChart()
              "
              [ngClass]="{
                'filter-active': facebookAdsKPIChartFilters.impressions,
                'filter-inactive': !facebookAdsKPIChartFilters.impressions
              }"
            >
              <div class="bullet impressions"></div>
              {{ "shared.impressions" | translate }}
            </button>
            <button
              class="filter"
              (click)="
                facebookAdsKPIChartFilters.views =
                  !facebookAdsKPIChartFilters.views;
                loadFacebookAdsKPIChart()
              "
              [ngClass]="{
                'filter-active': facebookAdsKPIChartFilters.views,
                'filter-inactive': !facebookAdsKPIChartFilters.views
              }"
            >
              <div class="bullet views"></div>
              {{ "shared.reach" | translate }}
            </button>
            <button
              class="filter"
              (click)="
                facebookAdsKPIChartFilters.engagements =
                  !facebookAdsKPIChartFilters.engagements;
                loadFacebookAdsKPIChart()
              "
              [ngClass]="{
                'filter-active': facebookAdsKPIChartFilters.engagements,
                'filter-inactive': !facebookAdsKPIChartFilters.engagements
              }"
            >
              <div class="bullet engagements"></div>
              {{ "shared.engagements" | translate }}
            </button>
            <button
              class="filter"
              (click)="
                facebookAdsKPIChartFilters.clicks =
                  !facebookAdsKPIChartFilters.clicks;
                loadFacebookAdsKPIChart()
              "
              [ngClass]="{
                'filter-active': facebookAdsKPIChartFilters.clicks,
                'filter-inactive': !facebookAdsKPIChartFilters.clicks
              }"
            >
              <div class="bullet clicks"></div>
              {{ "shared.clicks" | translate }}
            </button>
          </div>
        </div>
        <div class="line-chart">
          <app-chart [settings]="facebookAdsKPIChartSettings"></app-chart>
        </div>
        <div class="param-with-title">
          <div
            class="statistic-param"
            *ngIf="facebookAdsKPIChartFilters.impressions"
          >
            <div class="color-bar impressions">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.impressions" | translate }}</p>
              <p class="amount">
                {{ insights.facebookAdsStats.impressions | number: "1.0-2" }}
              </p>
            </div>
          </div>
          <div class="statistic-param" *ngIf="facebookAdsKPIChartFilters.views">
            <div class="color-bar views">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.reach" | translate }}</p>
              <p class="amount">
                {{ insights.facebookAdsStats.views | number: "1.0-2" }}
              </p>
            </div>
          </div>
          <div
            class="statistic-param"
            *ngIf="facebookAdsKPIChartFilters.engagements"
          >
            <div class="color-bar engagement">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.engagements" | translate }}</p>
              <p class="amount">
                {{
                  insights.facebookAdsStats.inlinePostEngagement
                    | number: "1.0-2"
                }}
              </p>
            </div>
          </div>
          <div
            class="statistic-param"
            *ngIf="facebookAdsKPIChartFilters.clicks"
          >
            <div class="color-bar link-clicks">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.clicks" | translate }}</p>
              <p class="amount">
                {{ insights.facebookAdsStats.linkClicks | number: "1.0-2" }}
              </p>
            </div>
          </div>
          <div class="statistic-param statistic-param--daily-chart-money">
            <p class="amount">
              {{ insights.currencySymbol
              }}{{ insights.facebookAdsBudget | number: "1.0-2" }}
            </p>
            <p class="type">
              {{ "brand.campaign.dashboard.totalBudget" | translate }}
            </p>
          </div>
          <div class="statistic-param statistic-param--daily-chart-money">
            <p class="amount">
              {{ insights.currencySymbol
              }}{{ insights.facebookAdsStats.spend | number: "1.0-2" }}
            </p>
            <p class="type">
              {{ "brand.campaign.dashboard.budgetSpent" | translate }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
  <!-- FACEBOOK POSTS CHART -->
  <ng-container *ngIf="insights.facebookPostsStats">
    <section class="posts-per-day">
      <div class="card">
        <div class="header">
          <h4>
            {{
              "brand.campaign.dashboard.postsPerDayCharTitle"
                | translate
                | uppercase
            }}
          </h4>
          <div class="filters" *ngIf="facebookPostsPerDayChartFilters">
            <button
              class="filter"
              (click)="
                facebookPostsPerDayChartFilters.impressions =
                  !facebookPostsPerDayChartFilters.impressions;
                loadFacebookPostsPerDayChart()
              "
              [ngClass]="{
                'filter-active': facebookPostsPerDayChartFilters.impressions,
                'filter-inactive': !facebookPostsPerDayChartFilters.impressions
              }"
            >
              <div class="bullet impressions"></div>
              {{ "shared.impressions" | translate }}
            </button>
            <button
              class="filter"
              (click)="
                facebookPostsPerDayChartFilters.likes =
                  !facebookPostsPerDayChartFilters.likes;
                loadFacebookPostsPerDayChart()
              "
              [ngClass]="{
                'filter-active': facebookPostsPerDayChartFilters.likes,
                'filter-inactive': !facebookPostsPerDayChartFilters.likes
              }"
            >
              <div class="bullet likes"></div>
              {{ "shared.likes" | translate }}
            </button>
            <button
              class="filter"
              (click)="
                facebookPostsPerDayChartFilters.comments =
                  !facebookPostsPerDayChartFilters.comments;
                loadFacebookPostsPerDayChart()
              "
              [ngClass]="{
                'filter-active': facebookPostsPerDayChartFilters.comments,
                'filter-inactive': !facebookPostsPerDayChartFilters.comments
              }"
            >
              <div class="bullet comments"></div>
              {{ "shared.comments" | translate }}
            </button>
            <button
              class="filter"
              (click)="
                facebookPostsPerDayChartFilters.shares =
                  !facebookPostsPerDayChartFilters.shares;
                loadFacebookPostsPerDayChart()
              "
              [ngClass]="{
                'filter-active': facebookPostsPerDayChartFilters.shares,
                'filter-inactive': !facebookPostsPerDayChartFilters.shares
              }"
            >
              <div class="bullet shares"></div>
              {{ "shared.shares" | translate }}
            </button>
            <button
              class="filter"
              (click)="
                facebookPostsPerDayChartFilters.clicks =
                  !facebookPostsPerDayChartFilters.clicks;
                loadFacebookPostsPerDayChart()
              "
              [ngClass]="{
                'filter-active': facebookPostsPerDayChartFilters.clicks,
                'filter-inactive': !facebookPostsPerDayChartFilters.clicks
              }"
            >
              <div class="bullet clicks"></div>
              {{ "shared.clicks" | translate }}
            </button>
          </div>
        </div>
        <div class="line-chart">
          <app-chart [settings]="facebookPostsPerDayChartSettings"></app-chart>
        </div>
        <div class="param-with-title">
          <div class="statistic-param">
            <div class="color-bar impressions">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.views" | translate }}</p>
              <p class="amount">
                {{ insights.facebookPostsStats.impressions | number: "1.0-2" }}
              </p>
            </div>
          </div>
          <div class="statistic-param">
            <div class="color-bar likes">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.likes" | translate }}</p>
              <p class="amount">
                {{ insights.facebookPostsStats.likes | number: "1.0-2" }}
              </p>
            </div>
          </div>
          <div class="statistic-param">
            <div class="color-bar comments">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.comments" | translate }}</p>
              <p class="amount">
                {{ insights.facebookPostsStats.comments | number: "1.0-2" }}
              </p>
            </div>
          </div>
          <div class="statistic-param">
            <div class="color-bar shares">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.shares" | translate }}</p>
              <p class="amount">
                {{ insights.facebookPostsStats.shares | number: "1.0-2" }}
              </p>
            </div>
          </div>
          <div class="statistic-param">
            <div class="color-bar link-clicks">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.clicks" | translate }}</p>
              <p class="amount">
                {{ insights.facebookPostsStats.clicks | number: "1.0-2" }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="posts-per-day">
      <div class="card">
        <div class="header">
          <h4>
            {{
              "brand.campaign.dashboard.instagramPostsPerDayCharTitle"
                | translate
                | uppercase
            }}
          </h4>
          <div class="filters" *ngIf="instagramPostsPerDayChartFilters">
            <button
              class="filter"
              (click)="
                instagramPostsPerDayChartFilters.impressions =
                  !instagramPostsPerDayChartFilters.impressions;
                loadInstagramPostsPerDayChart()
              "
              [ngClass]="{
                'filter-active': instagramPostsPerDayChartFilters.impressions,
                'filter-inactive': !instagramPostsPerDayChartFilters.impressions
              }"
            >
              <div class="bullet impressions"></div>
              {{ "shared.impressions" | translate }}
            </button>
            <button
              class="filter"
              (click)="
                instagramPostsPerDayChartFilters.engagement =
                  !instagramPostsPerDayChartFilters.engagement;
                loadInstagramPostsPerDayChart()
              "
              [ngClass]="{
                'filter-active': instagramPostsPerDayChartFilters.engagement,
                'filter-inactive': !instagramPostsPerDayChartFilters.engagement
              }"
            >
              <div class="bullet engagements"></div>
              {{ "shared.engagement" | translate }}
            </button>
            <button
              class="filter"
              (click)="
                instagramPostsPerDayChartFilters.reach =
                  !instagramPostsPerDayChartFilters.reach;
                loadInstagramPostsPerDayChart()
              "
              [ngClass]="{
                'filter-active': instagramPostsPerDayChartFilters.reach,
                'filter-inactive': !instagramPostsPerDayChartFilters.reach
              }"
            >
              <div class="bullet reach"></div>
              {{ "shared.reach" | translate }}
            </button>
            <button
              class="filter"
              (click)="
                instagramPostsPerDayChartFilters.videoViews =
                  !instagramPostsPerDayChartFilters.videoViews;
                loadInstagramPostsPerDayChart()
              "
              [ngClass]="{
                'filter-active': instagramPostsPerDayChartFilters.videoViews,
                'filter-inactive': !instagramPostsPerDayChartFilters.videoViews
              }"
            >
              <div class="bullet videoViews"></div>
              {{ "shared.videoViews" | translate }}
            </button>
          </div>
        </div>
        <div class="line-chart">
          <app-chart [settings]="instagramPostsPerDayChartSettings"></app-chart>
        </div>
        <div class="param-with-title">
          <div class="statistic-param">
            <div class="color-bar impressions">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.impressions" | translate }}</p>
              <p class="amount">
                {{
                  insights.instagramPostsStats.instagramImpressions
                    | number: "1.0-2"
                }}
              </p>
            </div>
          </div>
          <div class="statistic-param">
            <div class="color-bar engagement">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.engagement" | translate }}</p>
              <p class="amount">
                {{
                  insights.instagramPostsStats.instagramEngagement
                    | number: "1.0-2"
                }}
              </p>
            </div>
          </div>
          <div class="statistic-param">
            <div class="color-bar reach">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.reach" | translate }}</p>
              <p class="amount">
                {{
                  insights.instagramPostsStats.instagramReach | number: "1.0-2"
                }}
              </p>
            </div>
          </div>
          <div class="statistic-param">
            <div class="color-bar videoViews">&nbsp;</div>
            <div class="data">
              <p class="type">{{ "shared.videoViews" | translate }}</p>
              <p class="amount">
                {{
                  insights.instagramPostsStats.instagramVideoViews
                    | number: "1.0-2"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="insights.includePos" class="pos-orders-per-day">
      <h3>{{ "brand.campaign.dashboard.posTitle" | translate }}</h3>
      <div class="card">
        <h4>{{ "brand.campaign.dashboard.posSubTitle" | translate }}</h4>
        <div class="line-chart">
          <app-chart [settings]="posOrdersPerDayChartSettings"></app-chart>
        </div>
        <div class="param-with-title">
          <div class="statistic-param">
            <h4>{{ "brand.campaign.dashboard.posOrders" | translate }}</h4>
            <p class="amount">
              {{ insights.totalPosOrders | number: "1.0-2" }}
            </p>
            <p class="type">
              {{ "brand.campaign.dashboard.posOrdersMade" | translate }}
            </p>
          </div>
          <div class="statistic-param">
            <h4>{{ "brand.campaign.dashboard.postCosts" | translate }}</h4>
            <p class="amount">
              {{ insights.currencySymbol
              }}{{ insights.totalPosOrdersAmount | number: "1.0-2" }}
            </p>
            <p class="type">
              {{ "brand.campaign.dashboard.postTotalCost" | translate }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
  <section class="posts-per-day" *ngIf="showLinkedInCharts">
    <div class="card">
      <div class="header">
        <h4>
          {{
            "brand.campaign.dashboard.linkedInPostsPerDayCharTitle"
              | translate
              | uppercase
          }}
        </h4>
        <div class="filters" *ngIf="linkedInPostsPerDayChartFilters">
          <button
            class="filter"
            (click)="
              linkedInPostsPerDayChartFilters.impressions =
                !linkedInPostsPerDayChartFilters.impressions;
              loadLinkedInPostsPerDayChart()
            "
            [ngClass]="{
              'filter-active': linkedInPostsPerDayChartFilters.impressions,
              'filter-inactive': !linkedInPostsPerDayChartFilters.impressions
            }"
          >
            <div class="bullet impressions"></div>
            {{ "shared.impressions" | translate }}
          </button>
          <button
            class="filter"
            (click)="
              linkedInPostsPerDayChartFilters.likes =
                !linkedInPostsPerDayChartFilters.likes;
              loadLinkedInPostsPerDayChart()
            "
            [ngClass]="{
              'filter-active': linkedInPostsPerDayChartFilters.likes,
              'filter-inactive': !linkedInPostsPerDayChartFilters.likes
            }"
          >
            <div class="bullet likes"></div>
            {{ "shared.likes" | translate }}
          </button>
          <button
            class="filter"
            (click)="
              linkedInPostsPerDayChartFilters.comments =
                !linkedInPostsPerDayChartFilters.comments;
              loadLinkedInPostsPerDayChart()
            "
            [ngClass]="{
              'filter-active': linkedInPostsPerDayChartFilters.comments,
              'filter-inactive': !linkedInPostsPerDayChartFilters.comments
            }"
          >
            <div class="bullet comments"></div>
            {{ "shared.comments" | translate }}
          </button>
          <button
            class="filter"
            (click)="
              linkedInPostsPerDayChartFilters.shares =
                !linkedInPostsPerDayChartFilters.shares;
              loadLinkedInPostsPerDayChart()
            "
            [ngClass]="{
              'filter-active': linkedInPostsPerDayChartFilters.shares,
              'filter-inactive': !linkedInPostsPerDayChartFilters.shares
            }"
          >
            <div class="bullet shares"></div>
            {{ "shared.shares" | translate }}
          </button>
          <button
            class="filter"
            (click)="
              linkedInPostsPerDayChartFilters.clicks =
                !linkedInPostsPerDayChartFilters.clicks;
              loadLinkedInPostsPerDayChart()
            "
            [ngClass]="{
              'filter-active': linkedInPostsPerDayChartFilters.clicks,
              'filter-inactive': !linkedInPostsPerDayChartFilters.clicks
            }"
          >
            <div class="bullet clicks"></div>
            {{ "shared.clicks" | translate }}
          </button>
        </div>
      </div>
      <div class="line-chart">
        <app-chart [settings]="linkedInPostsPerDayChartSettings" />
      </div>
      <div class="param-with-title">
        <div class="statistic-param">
          <div class="color-bar impressions">&nbsp;</div>
          <div class="data">
            <p class="type">{{ "shared.views" | translate }}</p>
            <p class="amount">
              {{ insights.linkedinPostsStats.impressions | number: "1.0-2" }}
            </p>
          </div>
        </div>
        <div class="statistic-param">
          <div class="color-bar likes">&nbsp;</div>
          <div class="data">
            <p class="type">{{ "shared.likes" | translate }}</p>
            <p class="amount">
              {{ insights.linkedinPostsStats.likes | number: "1.0-2" }}
            </p>
          </div>
        </div>
        <div class="statistic-param">
          <div class="color-bar comments">&nbsp;</div>
          <div class="data">
            <p class="type">{{ "shared.comments" | translate }}</p>
            <p class="amount">
              {{ insights.linkedinPostsStats.comments | number: "1.0-2" }}
            </p>
          </div>
        </div>
        <div class="statistic-param">
          <div class="color-bar shares">&nbsp;</div>
          <div class="data">
            <p class="type">{{ "shared.shares" | translate }}</p>
            <p class="amount">
              {{ insights.linkedinPostsStats.shares | number: "1.0-2" }}
            </p>
          </div>
        </div>
        <div class="statistic-param">
          <div class="color-bar link-clicks">&nbsp;</div>
          <div class="data">
            <p class="type">{{ "shared.clicks" | translate }}</p>
            <p class="amount">
              {{ insights.linkedinPostsStats.clicks | number: "1.0-2" }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
