import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { Subscription } from "rxjs";

import { ChartData } from "../../charts/models/chart-data.model";
import { DoughnutChartSettings } from "../../charts/models/chart-settings.model";
import { CampaignType } from "../../enums/campaign.enums";
import { ExternalUrl } from "../../models/appData";
import { BrandCampaignInsight } from "../../models/brandCampaignInsight";
import { getTranslatedUrlSignal } from "../../services/language.service";
import {
  ChartLegendLine,
  ChartLegendLineWithColor,
  DoughnutChartWithLegendComponent,
} from "../doughnut-chart-with-legend/doughnut-chart-with-legend.component";
import { campaignBudgetDoughnutChartDataSettings } from "./chart-settings/campaign-budget-doughnut-chart-data-settings";
import { doughnutChartSettings } from "./chart-settings/doughnut-chart-settings";
import { doughnutWithTextChartSettings } from "./chart-settings/doughnut-chart-with-text-settings";
import { engagementChartData } from "./chart-settings/engagement-chart-data-settings";
import { partnerStatusChartData } from "./chart-settings/partner-status-chart-data-settings";
import { postsByTypeDoughnutChartDataSettings } from "./chart-settings/posts-by-type-doughnut-chart-data-settings";
import { totalPostsDoughnutChartDataSettings } from "./chart-settings/total-posts-doughnut-chart-data-settings";
import { DecimalPipe, NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ChartComponent } from "../chart/chart.component";
import { TooltipComponent } from "../tooltip/tooltip.component";
import { MatIconModule } from "@angular/material/icon";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-campaign-dashboard-stats-section",
  templateUrl: "./campaign-dashboard-stats-section.component.html",
  styleUrl: "./campaign-dashboard-stats-section.component.scss",
  standalone: true,
  imports: [
    ChartComponent,
    DecimalPipe,
    DoughnutChartWithLegendComponent,
    MatIconModule,
    NgIf,
    TooltipComponent,
    TranslateModule,
  ],
})
export class CampaignDashboardStatsSectionComponent
  implements OnInit, OnDestroy
{
  private currentInsights!: BrandCampaignInsight;

  @Input({ required: true }) public set insights(
    insights: BrandCampaignInsight,
  ) {
    this.currentInsights = insights;
    this.setUpCharts();
  }

  public get insights() {
    return this.currentInsights;
  }

  protected readonly CampaignType = CampaignType;
  protected campaignBudgetChartSettings!: DoughnutChartSettings;
  protected engagementPieChartSettings!: DoughnutChartSettings;
  protected partnerStatusPieChartSettings!: DoughnutChartSettings;
  protected postsByTypePieChartSettings!: DoughnutChartSettings;
  protected totalPostsPieChartSettings!: DoughnutChartSettings;
  protected engagementLegends: ChartLegendLine[] = [];
  protected partnerStatusLegends: ChartLegendLine[] = [];
  protected postsByTypeLegends: ChartLegendLine[] = [];
  protected totalPostsLegends: ChartLegendLine[] = [];

  private subscriptions = new Subscription();

  protected readonly contentEngagementInfoURL = getTranslatedUrlSignal(
    ExternalUrl.ContentEngagement,
  );

  public ngOnInit(): void {
    this.setUpCharts();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setUpCharts(): void {
    this.setTotalPostsLegends();
    this.setPostsByTypeLegends();
    this.setPartnerStatusLegends();
    this.setEngagementLegends();

    this.loadCampaignBudgetChart();
    this.loadTotalPostsChart();
    this.loadPostsByTypeChart();
    this.loadEngagementChart();
    this.loadPartnerStatusChart();
  }

  private loadCampaignBudgetChart(): void {
    this.campaignBudgetChartSettings = new DoughnutChartSettings(
      doughnutWithTextChartSettings,
      campaignBudgetDoughnutChartDataSettings.map(
        (item) =>
          new ChartData(
            item.name,
            this.insights[item.valueKey] ?? 0,
            item.color,
          ),
      ),
    );
  }

  private loadTotalPostsChart(): void {
    this.totalPostsPieChartSettings = new DoughnutChartSettings(
      doughnutChartSettings,
      totalPostsDoughnutChartDataSettings.map(
        (item) =>
          new ChartData(
            item.name,
            this.insights[item.valueKey] ?? 0,
            item.color,
          ),
      ),
    );
  }

  private loadPostsByTypeChart(): void {
    this.postsByTypePieChartSettings = new DoughnutChartSettings(
      doughnutChartSettings,
      postsByTypeDoughnutChartDataSettings.map(
        (item) =>
          new ChartData(
            item.name,
            this.insights.totalPosts[item.valueKey] ?? 0,
            item.color,
          ),
      ),
    );
  }

  private loadEngagementChart(): void {
    this.engagementPieChartSettings = new DoughnutChartSettings(
      doughnutChartSettings,
      engagementChartData
        .map((item) =>
          item.prop in this.insights.engagementChart
            ? new ChartData(
                `brand.campaign.dashboard.engagementLevelsChart.${item.prop}`,
                this.insights.engagementChart[item.prop] ?? 0,
                String(item.color),
              )
            : undefined,
        )
        .filter((item): item is ChartData => item !== undefined),
    );
  }

  private loadPartnerStatusChart(): void {
    this.partnerStatusPieChartSettings = new DoughnutChartSettings(
      doughnutChartSettings,
      partnerStatusChartData.map(
        (item) =>
          new ChartData(
            `brand.campaign.dashboard.engagementLevelsChart.${item.prop}`,
            // this.insights.engagementChart[item.prop] ?? 0,
            // item.color,
            this.insights.partnerStatusChart[item.prop] ?? 0,
            String(item.color),
          ),
      ),
    );
  }

  protected costPerImpression(): string {
    if (!this.insights || !this.insights.adsStats) {
      return "0";
    }

    const value =
      this.insights.adsStats.impressions > 0
        ? this.insights.campaignSpentBudget / this.insights.adsStats.impressions
        : 0;

    return (value * 1000).toFixed(2);
  }

  private arePostsByTypeChartAllCero(): boolean {
    return (
      this.insights &&
      this.insights.totalPosts.totalCarousel === 0 &&
      this.insights.totalPosts.totalGif === 0 &&
      this.insights.totalPosts.totalImages === 0 &&
      this.insights.totalPosts.totalVideos === 0
    );
  }

  private setTotalPostsLegends(): void {
    this.totalPostsLegends = [
      {
        amount: this.insights.totalPublishedFacebookPosts,
        text: "brand.campaign.dashboard.facebookPosts",
        colorClass: "blue",
      },
      {
        amount: this.insights.totalPublishedInstagramPosts,
        text: "brand.campaign.dashboard.instagramPosts",
        colorClass: "purple",
      },
      {
        amount: this.insights.totalPublishedLinkedinPosts,
        text: "brand.campaign.dashboard.linkedInPosts",
        colorClass: "lightblue",
      },
    ];

    if (this.insights.promoAdsEnabled && this.insights.useFacebookAds) {
      this.totalPostsLegends.push({
        amount: this.insights.totalPublishedFacebookAds,
        text: "brand.campaign.dashboard.facebookAds",
        colorClass: "orange",
      });
    }

    if (this.insights.promoAdsEnabled && this.insights.useGoogleAds) {
      this.totalPostsLegends.push({
        amount: this.insights.totalPublishedGoogleAds,
        text: "brand.campaign.dashboard.googleAds",
        colorClass: "green",
      });
    }
  }

  private setPostsByTypeLegends(): void {
    const postsByTypeLegends: (ChartLegendLine | false)[] = [
      (this.insights.totalPosts.totalImages > 0 ||
        this.arePostsByTypeChartAllCero()) && {
        amount: this.insights.totalPosts.totalImages,
        text: "shared.image",
        colorClass: "red",
      },
      (this.insights.totalPosts.totalVideos > 0 ||
        this.arePostsByTypeChartAllCero()) && {
        amount: this.insights.totalPosts.totalVideos,
        text: "shared.video",
        colorClass: "yellow",
      },
      (this.insights.totalPosts.totalCarousel > 0 ||
        this.arePostsByTypeChartAllCero()) && {
        amount: this.insights.totalPosts.totalCarousel,
        text: "brand.campaign.dashboard.chartByType.multiImage",
        colorClass: "blue",
      },
      (this.insights.totalPosts.totalGif > 0 ||
        this.arePostsByTypeChartAllCero()) && {
        amount: this.insights.totalPosts.totalGif,
        text: "shared.gif",
        colorClass: "orange",
      },
    ];

    this.postsByTypeLegends = postsByTypeLegends.filter(
      (item): item is ChartLegendLine => item !== false,
    );
  }

  private setPartnerStatusLegends(): void {
    this.partnerStatusLegends = partnerStatusChartData
      .map((item) => {
        if (
          !this.insights ||
          !(item.prop in this.insights.partnerStatusChart)
        ) {
          return undefined;
        }

        return {
          amount: this.insights.partnerStatusChart[item.prop],
          text: "brand.campaign.dashboard.engagementLevelsChart." + item.prop,
          color: item.color,
        };
      })
      .filter(
        (item): item is ChartLegendLineWithColor => typeof item !== "undefined",
      );
  }

  private setEngagementLegends(): void {
    this.engagementLegends = engagementChartData
      .map((item) => {
        if (!this.insights || !(item.prop in this.insights.engagementChart)) {
          return undefined;
        }

        return {
          amount: this.insights.engagementChart[item.prop],
          text: "brand.campaign.dashboard.engagementLevelsChart." + item.prop,
          color: item.color,
        };
      })
      .filter(
        (item): item is ChartLegendLineWithColor => typeof item !== "undefined",
      );
  }
}
