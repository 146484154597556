import { Component, Input, OnChanges } from "@angular/core";
import { DoughnutChartSettings } from "../../charts/models/chart-settings.model";
import { ChartComponent } from "../chart/chart.component";
import { TranslateModule } from "@ngx-translate/core";
import { DecimalPipe, NgClass } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";

export interface ChartLegendLine {
  amount: number;
  text: string;
  color?: `#${string}`;
  colorClass?: string;
}

export interface ChartLegendLineWithColor extends ChartLegendLine {
  color: `#${string}`;
}

export interface ChartLegendLineWithColorClass extends ChartLegendLine {
  colorClass: string;
}

@Component({
  selector: "app-doughnut-chart-with-legend",
  templateUrl: "./doughnut-chart-with-legend.component.html",
  styleUrl: "./doughnut-chart-with-legend.component.scss",
  standalone: true,
  imports: [
    ChartComponent,
    DecimalPipe,
    MatIconModule,
    NgClass,
    TranslateModule,
  ],
})
export class DoughnutChartWithLegendComponent implements OnChanges {
  @Input({ required: true }) public legendLines!: ChartLegendLine[];
  @Input({ required: true }) public name!: string;
  @Input({ required: true }) public settings!: DoughnutChartSettings;
  @Input({ required: true }) public totalText!: string;
  @Input({ required: true }) public totalValue!: number;
  @Input() public infoLink?: string;

  protected ready = false;

  public ngOnChanges(): void {
    this.ready = this.settings && this.legendLines.length > 0;
  }
}
