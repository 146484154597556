import type { ChartConfiguration } from "chart.js";

export const KPI_CHART_SETTINGS = (): ChartConfiguration<"line"> => ({
  data: {
    datasets: [],
  },
  plugins: [],
  type: "line",
  options: {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          color: "grey",
          maxTicksLimit: 10,
          maxRotation: 0,
          crossAlign: "near",
          font: {
            family: '"Open Sans", sans-serif',
            lineHeight: 3,
            size: 14,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#e5e5e5",
          lineWidth: 2,
          drawTicks: false,
        },
        border: {
          dash: [2],
          display: false,
        },
        ticks: {
          color: "grey",
          crossAlign: "far",
          stepSize: 1,
          font: {
            family: '"Open Sans", sans-serif',
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: "custom",
        backgroundColor: "rgba(255,255,255,0.9)",
        titleColor: "#000",
        bodyColor: "#000",
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
  },
});
