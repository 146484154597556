@if (ready) {
  <h4 class="title">
    {{ name | translate }}

    @if (infoLink) {
      <a class="info-link" target="_blank" href="{{ infoLink | translate }}">
        <mat-icon>info</mat-icon>
      </a>
    }
  </h4>
  <div class="content">
    <app-chart class="chart" [settings]="settings" />
    <div class="legend">
      @for (line of legendLines; track line.text) {
        <div class="legend-line">
          <div
            class="color"
            [ngClass]="line.colorClass"
            [style.backgroundColor]="line.color"
          ></div>
          <p>
            <span class="amount">{{ line.amount | number: "1.0-2" }}</span>
            {{ line.text | translate }}
          </p>
        </div>
      }
    </div>
  </div>
  <div class="total">
    {{ totalValue | number: "1.0-2" }}
    <br />
    {{ totalText | translate }}
  </div>
}
