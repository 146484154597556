import { DataSettings } from "../../../charts/models/data-settings.model";
import { GoogleAdStats } from "../../../models/brandCampaignInsight";
import { AdsKPIChartFilters } from "./types";

export const googleAdsKPIChartDataSettings: DataSettings<
  keyof GoogleAdStats,
  keyof AdsKPIChartFilters
>[] = [
  new DataSettings(
    "impressions",
    "impressions",
    "partner.dashboard.KPIChart.impressions",
    "#4a90e2",
  ),
  new DataSettings(
    "views",
    "views",
    "partner.dashboard.KPIChart.reach",
    "#f28a3d",
  ),
  new DataSettings(
    "linkClicks",
    "clicks",
    "partner.dashboard.KPIChart.clicks",
    "#1dbf77",
  ),
];
